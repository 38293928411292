/* Most CSS rules are in JS components */
html {
  font-family:
    'Roboto',
    'Open Sans',
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    Fira Sans,
    Droid Sans,
    Helvetica Neue,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.mapboxgl-popup-content > .popup > * {
  margin: 0;
}

a.popup-link:link{
  text-decoration: underline; 
}
a.popup-link:visited,

a.popup-link:active { 
  color: #3366FF; text-decoration: none; 
}

a.popup-link:hover { 
  color: #79ddf2; text-decoration: underline; 
}